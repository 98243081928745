import React from "react"
import Plx from 'react-plx';

import '../assets/stylesheets/shared.scss'

import Layout from "../components/layout"
import Seo from "../components/seo"

import Header from '../components/header'
import GetInTouch from "../components/get-in-touch"
import Footer from "../components/footer"

import {parallaxCircles1, parallaxCircles2 } from '../utils'


const IndexPage = () => (
  <Layout>
    <Seo title="Terms of Use" keywords={[]} />
    <Header />
    <article className="page">
      <div className="container">
        <Plx parallaxData={ parallaxCircles1 } className="hero__circle" />
        <Plx parallaxData={ parallaxCircles2 } className="vision__circle" />

        <div className="circle page__circle-1" />
        <div className="circle page__circle-2" />
        <div className="circle page__circle-3" />
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="page__lead">
              <p className="g-subtitle">Afrivest</p>
              <h1 className="g-h2">Terms Of Use</h1>
              <p className="g-p2">This website is provided to you by Afrivest Technology. These Terms of Use apply to Afrivest.io site and to its mobile version (the “Website”).</p>
            </div>
            <p className="g-p2">By choosing to use of the Website, you consent to these Terms of Use and agree to be bound by them. The Website is used to provide you with information about our business and services and to enable:</p>
            <ul className="g-list">
              <li>
                <p className="g-p2">contact between Afrivest and you through forms available on the Website;</p>
              </li>
              <li>
                <p className="g-p2">downloading materials;</p>
              </li>
              <li>
                <p className="g-p2">applying for vacancies.</p>
              </li>
            </ul>
            <p className="g-p2">The minimum technical requirements to use the Website are as follows:</p>
            <ul className="g-list">
              <li>
                <p className="g-p2">Internet connection;</p>
              </li>
              <li>
                <p className="g-p2">the most up to date version of one of the following browsers with enabled cookies and JavaScript: Microsoft Internet Explorer, Mozilla Firefox, Opera, Google Chrome, Apple Safari.</p>
              </li>
            </ul>


            <p className="g-p2">Enabling cookies will help you make the most of your experience using the Website. For more information on cookies, please refer to our Privacy and Cookie Policy.</p>

            <p className="g-p2">You are only authorized to view, copy, print, and distribute (but not modify) the content on the Website, provided that such use is only for noncommercial purposes.</p>

            <p className="g-p2">The Website and its contents are protected by copyrights, trademarks, and other laws of the Republic of Poland, therefore, you are not authorized to copy or use in any form any software or technology embodied or described in this website. You are not allowed to use the Website for unlawful purposes or to provide unlawful content through the Website. Through the Website, you are able to link to other websites which are not under the control of Afrivest. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>

            <p className="g-p2">We do not warrant that the Website will be fully accurate, compatible, secure, error-free, or virus-free. Please note that we will not be liable for any direct, indirect, special, incidental, consequential, or punitive damages or any other damages whatsoever, whether in an action of contract, statute, tort (including, without limitation, negligence), or otherwise, relating to or arising out of the use of the Website, even if we knew, or should have known, of the possibility of such damages. The above is applicable to the fullest extent permitted by law.</p>

            <p className="g-p2">If you wish to file a complaint with regard to the functioning of the Website, you can do that by emailing us at contact@Afrivest.io. We will respond to your complaint within 30 working days.</p>

            <p className="g-p2">We may revise these Terms of Use at any time in our sole discretion by posting such revised Terms of Use on the Website. Such revisions shall be effective and binding on you upon posting unless explicitly stated otherwise by us. Your continued use of the Website following changes to these Website Terms of Use constitutes your agreement to the revised Terms of Use.</p>


          </div>
        </div>
      </div>
    </article>
    <GetInTouch />
    <Footer/>
  </Layout>
)

export default IndexPage
